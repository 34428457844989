<template>
  <div>
    <AppHeader />
    <v-divider></v-divider>
    <v-layout wrap justify-center fill-height>
      <v-flex xs12 sm12 md12 lg2 hidden-md-and-down>
        <v-navigation-drawer
          class="fixedSidebar"
          v-model="sideNav"
          clipped
          dark
          src="./../assets/images/sd1.jpg"
          overlay-color="white"
          width="100%"
          height="100vh"
          permanent
          color="#005f32"
        >
          <v-layout wrap>
            <v-flex xs12 text-left py-2 text-uppercase align-self-center>
              <v-list dense v-for="(main, i) in appMenu" :key="i">
                <v-layout wrap>
                  <v-flex xs12 py-2>
                    <!-- v-if="main._id !== '60ed5bd072b4ca543c57424d'" -->
                    <v-list-group
                      no-action
                      :value="false"
                      active-class="activated"
                    >
                      <template v-slot:activator>
                        <v-list-item-title
                          style="
                            font-size: 13px;
                            letter-spacing: 2px;
                            color: black;
                            cursor: pointer;
                            font-family: kumbhMedium;
                          "
                        >
                          <span
                            class="text-none"
                            style="color: #ffffff !important"
                          >
                            {{ main.menu.name }}
                          </span>
                        </v-list-item-title>
                      </template>
                      <!-- <v-flex xs12 py-1 px-4>
                        <div style="border-bottom: 1px dotted black"></div>
                      </v-flex> -->
                      <template v-for="(sub, i) in main.submenus">
                        <v-flex
                          xs12
                          text-left
                          pl-6
                          py-1
                          :style="
                            $route.path == sub.route
                              ? 'background:rgb(240, 115, 5)'
                              : ''
                          "
                          :key="i"
                        >
                          <v-layout wrap justify-center>
                            <v-flex xs12>
                              <router-link :to="sub.route">
                                <span
                                  :style="
                                    $route.path == sub.route
                                      ? {
                                          'font-family': 'kumbhEBold',
                                          'font-size': ' 13px',
                                        }
                                      : {
                                          'font-family': 'kumbhMedium',
                                          'font-size': ' 13px',
                                        }
                                  "
                                  style="letter-spacing: 3px; cursor: pointer"
                                >
                                  <span
                                    class="text-none"
                                    :style="
                                      $route.path == sub.route
                                        ? 'color:#005f32'
                                        : 'color: #ffffff'
                                    "
                                  >
                                    {{ sub.name }}
                                  </span>
                                </span>
                              </router-link>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 py-1 px-4 pl-6 :key="sub._d">
                          <div style="border-bottom: 1px solid grey"></div>
                        </v-flex>
                      </template>
                    </v-list-group>
                    <!-- <v-list-group v-else> -->
                    <!-- <v-list dark dense v-else>
                      <template v-for="(item, i) in admin">
                        <v-flex xs12 pl-4 align-self-center text-left :key="i">
                          <v-layout wrap justify-center>
                            <v-flex xs12>
                              <router-link :to="item.route">
                                <span
                                  :style="
                                    $route.path == item.route
                                      ? {
                                          'font-family': 'poppinsbold',
                                          'font-size': ' 16px',
                                        }
                                      : {
                                          'font-family': 'kumbhEBold',
                                          'font-size': ' 13px',
                                        }
                                  "
                                  style="
                                    font-size: 13px;
                                    color: black;
                                    letter-spacing: 3px;
                                    cursor: pointer;
                                  "
                                >
                                  {{ item.name }}
                                </span>
                              </router-link>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      
                      </template>
                    </v-list> -->
                  </v-flex>
                </v-layout>
                <!-- </v-list-group> -->
              </v-list>
            </v-flex>
          </v-layout>
        </v-navigation-drawer>
      </v-flex>
      <v-flex xs12 sm12 md12 lg10 pt-12 pt-lg-0>
        <v-layout
          wrap
          justify-center
          :style="
            $route.name == 'Dashboard'
              ? 'background-color: #FFFFFF'
              : 'background-color: #F5F5DC'
          "
        >
          <v-flex xs12 pa-6>
            <v-card
              tile
              min-height="100vh"
              :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
              :flat="$route.name == 'Dashboard' ? true : false"
            >
              <v-layout wrap justify-center>
                <v-flex xs12 pa-4>
                  <router-view :key="$route.fullPath"></router-view>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import AppHeader from "./../layout/AppHeader";
export default {
  components: {
    AppHeader,
  },
  data() {
    return {
      sideNav: true,
      admin: [{ name: "ADMINS", route: "/Admins" }],

      NewEmpMenu: [
        {
          menu: {
            name: "Dashboard",
          },
          submenus:[
            {
              route:"/",
              name:"Dashboard"
            }

          ]
        },
        {
          menu: {
            name: "Forms",
          },
          submenus:[
            {
              route:"/YourApplications",
              name:"Forms & Policies"
            }

          ]
        },
      ],

      menus: [
        {
          name: "Dashboard",
          subMenu: [
            {
              name: "Dashboard",
              route: "/",
            },
          ],
        },

        {
          name: "Category",
          subMenu: [
            {
              name: "Category List",
              route: "/CategoryList",
            },
          ],
        },
        {
          name: "Job Title",
          subMenu: [
            {
              name: "Title List",
              route: "/TitleList",
            },
          ],
        },

        {
          name: "Volunteer Title",
          subMenu: [
            {
              name: "Title List",
              route: "/Volunteer",
            },
          ],
        },
        {
          name: "Job Applications",
          subMenu: [
            {
              name: "Applications",
              route: "/Applications",
            },
          ],
        },
        {
          name: "Volunteer Applications",
          subMenu: [
            {
              name: "Volunteer Applications",
              route: "/volunteerApplication",
            },
          ],
        },
        {
          name: "Employee ",
          subMenu: [
            {
              name: "Employee Document",
              route: "/employeedocument",
            },
            {
              name: "Employee List",
              route: "/employeelist",
            },
          ],
        },

        {
          name: "Products",
          subMenu: [
            {
              name: "Products",
              route: "/products",
            },
            {
              name: "Add Product",
              route: "/addProducts",
            },

            {
              name: "Add Product-Type",
              route: "/ProductType",
            },
            {
              name: "Promocode",
              route: "/promocode",
            },
          ],
        },
        {
          name: "Purchase",
          subMenu: [
            {
              name: "Purchase",
              route: "/purchaseReports",
            },
          ],
        },
        {
          name: "HomePage",
          subMenu: [
            {
              name: "About us",
              route: "/aboutUs",
            },
            {
              name: "Video",
              route: "/Video",
            },
            {
              name: "Announcements",
              route: "/Announcements",
            },
          ],
        },
        {
          name: "WTI Contest",
          subMenu: [
            {
              name: "Applications",
              route: "/quizDetails",
            },
          ],
        },
        {
          name: "Conservation Clock",
          subMenu: [
            {
              name: "Conservation Clock",
              route: "/conservationClock",
            },
          ],
        },
        {
          name: "Settings",
          subMenu: [
            {
              name: "Roles",
              route: "/Roles",
            },
            {
              name: "Departments",
              route: "/Departments",
            },
            {
              name: "Projects",
              route: "/Projects",
            },
            {
              name: "Positions",
              route: "/Positions",
            },
            
          ],
        },
      ],
    };
  },
  computed: {
    appType() {

      return this.$store.state.userType;
    },
    appMenu() {
      // console.log("menus", store.state.menus);
      if (this.$store.state.menus.length > 0 && localStorage.getItem("positionId") != null) {
        return this.$store.state.menus;
      } else {
        return this.NewEmpMenu;
      }
    },
    navItems() {
      if (this.appType == "subadmin") return this.userNavItemsSubAdmin;
      else if (this.appType == "programadmin")
        return this.userNavItemsProgramAdmin;
      else if (this.appType == "staticcontentadmin")
        return this.userNavItemsStaticAdmin;
      else if (this.appType == "accountsadmin")
        return this.userNavItemsAccountsAdmin;
      else return this.userNavItems;
    },
  },
};
</script>
<style>
@media only screen and (min-device-width: 767px) {
  .fixedSidebar {
    /* position: fixed; */

    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 50;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .fixedSidebar {
    display: none !important;
  }
}
/* .activated { */
.activated {
  color: rgb(240, 115, 5) !important;
}
.deactivated {
  color: rgb(240, 115, 5) !important;
}
/* } */
</style>
